import Article from '../Article';
import ArticleGroup from '../ArticleGroup';
import Document from '../Document';
import Media from './Media';
import Page from '../Page';
import Product from '../Product';
import ProductGroup from '../ProductGroup';
import Slice from './Slice';

class Hero extends Slice {
  constructor(slice, siteUID) {
    super(slice);

    this.caption = slice.caption;
    this.linkLabel = slice.linkTarget?.linkLabel || null;
    this.linkTarget = Hero.parseLinkTarget(slice, siteUID);
    this.media = new Media(slice);
    this.title = slice.title;

    this.siteUID = siteUID;
  }

  static parseLinkTarget(slice, siteUID) {
    switch (slice.linkTarget?.linkType) {
      case 'document':
        return Hero.parseDocumentLink(slice.linkTarget.documentLink, siteUID);
      case 'product':
        return new Product(
          {
            path: slice.linkTarget.productLink.uid.current,
          },
          siteUID
        );
      case 'url':
        return slice.linkTarget.urlLink;
    }
  }

  static parseDocumentLink(documentLink, siteUID) {
    if (!documentLink) return null;

    switch (documentLink._type) {
      case 'article':
        return new Article(documentLink, siteUID);
      case 'articleGroup':
        return new ArticleGroup(documentLink, siteUID);
      case 'page':
        return new Page(documentLink, siteUID);
      case 'productGroup':
        return new ProductGroup(documentLink, siteUID);
    }
  }
}

export default Hero;
