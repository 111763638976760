import Hero from './Hero';
import Slice from './Slice';

class ProductGroups extends Slice {
  constructor(slice, siteUID) {
    super(slice);
    this.productGroups = slice.productGroups.map(
      (productGroup) => new Hero(productGroup, siteUID)
    );
  }
}

export default ProductGroups;
