import { graphql } from 'gatsby';
import React, { Component } from 'react';

import FrontPageComponent from '../components/front-page/FrontPage';
import FrontPageModel from '../models/FrontPage';
import jsonLd from './schemas';
import ProductFragment from './fragments/Product';
import ProductModel from '../models/Product';
import { getProductsForSlices } from '../utils/utils';
import { webSiteSchema } from './schemas';

export const query = graphql`
  query ($id: String!, $productIdsAll: [Int]) {
    sanityFrontPage(id: { eq: $id }) {
      id
      productGroups {
        ... on SanityProductGroupsSlice {
          ...ProductGroupsSlice
        }
      }
      products {
        ... on SanityCollectionSlice {
          ...CollectionSlice
        }
        ... on SanityProductsSlice {
          ...ProductsSlice
        }
      }
      slices {
        ... on SanityCollectionSlice {
          ...CollectionSlice
        }
        ... on SanityHeroSlice {
          ...HeroSlice
        }
        ... on SanityProductsSlice {
          ...ProductsSlice
        }
      }
      superHeros {
        ... on SanityHeroSlice {
          ...HeroSlice
        }
      }
    }
    allInRiverProduct(filter: { entityId: { in: $productIdsAll } }) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

class Index extends Component {
  render() {
    const { brand, site, path } = this.props;

    const { productIdsSlices, siteUID } = this.props.pageContext;
    const { allInRiverProduct, sanityFrontPage } = this.props.data;

    const products = getProductsForSlices(
      productIdsSlices,
      allInRiverProduct,
      ProductModel,
      siteUID,
      site
    );
    const frontPage = new FrontPageModel(sanityFrontPage, siteUID, products);

    return (
      <FrontPageComponent
        frontPage={frontPage}
        jsonLd={jsonLd(brand, site, path, frontPage)}
        site={site}
      />
    );
  }
}

export default Index;
