import React, { Component } from 'react';

import animations from './animations';

class Animation extends Component {
  render() {
    const animationComponent = () => {
      const animation = animations[this.props.site.brandUID];

      switch (animation.type) {
        case 'GIF':
          return animation.data;
        case 'SVG':
          return animation.data;
        default:
          return null;
      }
    };

    return <div className="animation">{animationComponent()}</div>;
  }
}

export default Animation;
