import React, { Component } from 'react';

import Drip from '../../assets/images/drip.gif';
import { Spool } from '../_common/Icons';

export default {
  'mustad-fishing': {
    type: 'GIF',
    data: <img src={Drip} />,
  },
  'tuf-line': {
    type: 'SVG',
    data: Spool,
  },
  livetarget: {
    type: 'SVG',
    data: null,
  },
};
